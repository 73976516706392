exports.onClientEntry = () => {
  if (window.heap && typeof window.heap.addUserProperties !== "undefined") {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const contactEmail = urlParams.get("contact_email")
    if (contactEmail) {
      const contactEmailDecoded = decodeURIComponent(
        contactEmail.replace(/\+/g, "%20")
      )
      window.heap.addUserProperties({ contactEmail: contactEmailDecoded })
    }
  }
}
